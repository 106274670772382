<script setup lang="ts">
interface OrganizionLogoProps {
  name: string
  logoUrl?: string
}

const props = withDefaults(
  defineProps<{
    organization: OrganizionLogoProps
    showLogo?: boolean
    showTooltip?: boolean
  }>(),
  {
    showLogo: false,
    showTooltip: true,
  },
)

// false = don't show the avatar, but the logo
const asAvatar = computed(() => !props.organization.logoUrl || !props.showLogo)

// take the first 2 chars of the organization name for now
const initials = computed(() => props.organization.name?.substring(0, 2) || '')
</script>

<template>
  <BaseAvatar
    v-if="asAvatar"
    :src="props.organization.logoUrl || undefined"
    :text="initials"
    :class="getRandomColor()"
    :data-nui-tooltip="props.organization.name"
  />
  <img
    v-else
    :src="props.organization.logoUrl || undefined"
    :text="initials"
    :class="getRandomColor()"
    class="border-muted-200 dark:border-muted-700 max-w-[400px] rounded-lg border-2 border-solid"
    :data-nui-tooltip="props.organization.name"
  >
</template>
